<template>
  <base-section space="0">
    <div v-for="(item, id) in m_arrContent" :key="id">
      <k-d-a-project-detail-point-card
        :str-title="item.strTitle"
        :str-bold="item.strBold"
        :str-description="item.strDescription"
        :img-consult="item.imgSrc"
        :num-index="id"
      />
    </div>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDAProjectDetailPoint',

  components: {
    KDAProjectDetailPointCard: () => import('@/components/KDAProjectDetailPointCard')
  },

  mixins: [BaseBreakpoint],

  computed: {
    m_currentRoute() {
      return this.$route.params.strProject;
    },
    m_arrContent() {
      const pointConsult = [
        {
          strTitle: this.$vuetify.lang.t('$vuetify.kda.project.point1'),
          strDescription: this.$vuetify.lang.t(
            `$vuetify.kda.project.detail.item-${this.m_currentRoute}.desc-point1`
          )
        },
        {
          strTitle: this.$vuetify.lang.t('$vuetify.kda.project.point2'),
          strDescription: this.$vuetify.lang.t(
            `$vuetify.kda.project.detail.item-${this.m_currentRoute}.desc-point2`
          )
        },
        {
          strTitle: this.$vuetify.lang.t('$vuetify.kda.project.point3'),
          strBold: this.$vuetify.lang.t(
            `$vuetify.kda.project.detail.item-${this.m_currentRoute}.sub-point3`
          ),
          strDescription: this.$vuetify.lang.t(
            `$vuetify.kda.project.detail.item-${this.m_currentRoute}.desc-point3`
          )
        }
      ];

      for (let i = 0; i < pointConsult.length; i++) {
        pointConsult[i].imgSrc = require(`@/assets/photos/project-detail-${i + 1}.png`);
      }

      return pointConsult;
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.background {
  background-color: #fbfbfb;
}
</style>
